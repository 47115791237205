import React from 'react';

class CourseGridItem extends React.Component {
  state = {
    width: '',
    show: false
  };

  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCardDisplay = () => {
    const currentState = this.state.show;
    this.setState({ show: !currentState });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 1200;

    return (
      <React.Fragment>
        {isMobile && (
          <div
            className={this.state.show ? 'coursegrid__item mobile  hide' : 'coursegrid__item mobile'}
            onClick={this.handleCardDisplay}
            onBlur={this.handleCardDisplay}
            style={{ backgroundImage: `url(${this.props.cardImage})` }}
            role="presentation"
          >
            <div className="coursegrid__content">
             {this.props.cardTitle && <h3>{this.props.cardTitle}</h3>}
            </div>
          </div>
        )}

        <div
          className={this.state.show ? 'coursegrid__item' : 'coursegrid__item hide'}
          style={{
            backgroundImage: `url(${this.props.cardImage})`,
            justifyContent: `${this.props.justifyContent}`,
            alignItems: `${this.props.alignItems}`
          }}
          onClick={this.handleCardDisplay}
          onBlur={this.handleCardDisplay}
          role="presentation"
        >
          <div className="coursegrid__content" style={{width: `${this.props.width}%`}}>
            {this.props.cardTitle && <h3>{this.props.cardTitle}</h3>}
            {this.props.cardText && <p>{this.props.cardText}</p>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default CourseGridItem;
