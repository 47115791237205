import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import MonthSlider from "../MonthSlider";

const SchoolCard = (props) => {
  return (
    <div className="schoolcard">
      <div className={props.rowType}>
        <div className="col">
          <div className="schoolcard__image">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="schoolcard__content">
            <h3>{props.header}</h3>
            <div className="prices">
              <h5>{props.priceOne}</h5>
              <h5>{props.priceTwo}</h5>
            </div>
            {props.children}
            {props.monthSliderQuery && (
                <MonthSlider
                    brand="Motorrad"
                    monthSliderHeader={props.monthSliderHeader}
                    backgroundColor="#E5E5E5"
                    urlQuery={props.monthSliderQuery}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchoolCard;
